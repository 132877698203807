import React from "react"

import GlobalCollection from "../config/GlobalCollection"
import Brainspotting from "../modules/specialisaties/Brainspotting"

const BrainspottingPage = () => (
  <GlobalCollection>
    <Brainspotting />
  </GlobalCollection>
)

export default BrainspottingPage
