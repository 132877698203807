import React from "react"
import styled from "../../config"
import { Flex, Box } from "reflexbox"
import TextBlock from "../../components/TextBlock"
import Blob from "../../components/Blob"
import { Link } from "gatsby"

const Depressie = () => (
  <DepressieWrapper>
    <Blob cheight="250px" />
    <Flex justifyContent="center" py={5}>
      <h1>Brainspotting</h1>
    </Flex>
    <Box p={3}>
      <StyledLink to="/specialisaties"> Terug</StyledLink>
      <TextBlock className="slanted centered">
        Brainspotting: Waar we kijken bepaalt hoe we ons voelen.
      </TextBlock>
      <VideoWrapper>
        <Stylediframe
          src="https://player.vimeo.com/video/186019577?h=a2e39cd2e7"
          width="640"
          height="360"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
        ></Stylediframe>
      </VideoWrapper>
      <TextBlock>
        Brainspotting werd vanaf 2003 ontwikkeld door <b>David Grand</b>{" "}
        uitgaande van zijn ervaringen bij het uitvoeren van EMDR. Wereldwijd
        wordt Brainspotting met succes toegepast, veelal bij trauma en
        blokkades, maar ook bij het optimaliseren van de prestaties van
        sporters, musici en artiesten.
      </TextBlock>
      <TextBlock>
        Elke levensgebeurtenis die een lichamelijk of emotioneel letsel
        veroorzaakt, waarbij iemand zich door pijn of verdriet overweldigd
        voelt, machteloos of gevangen, wordt opgeslagen in onze hersenen. Deze
        ingrijpende gebeurtenissen kunnen de verwerkingscapaciteiten van onze
        hersenen overweldigen, waardoor er delen van zo’n onverwerkte
        gebeurtenis achterblijven. Met Brainspotting gaan we hiermee aan de
        slag. Het is een psychotherapeutische methode die zich richt op het{" "}
        <b>verwerken van deze bewuste en/of onbewuste blokkades</b> die ons
        dagelijks functioneren op een negatieve wijze beïnvloeden.
      </TextBlock>
      <TextBlock>
        Een 'Brainspot' is de stand of positie van de ogen die gerelateerd is
        aan de energetische, emotionele activering van een traumatische of
        emotioneel geladen ervaring in het brein. Als iemand over iets praat,
        kijkt hij of zij naar een bepaald punt. Als die blik (oogpositie) op dat
        punt vastgehouden wordt, raken relevante gebieden in de hersenen
        gestimuleerd waardoor informatie die in die gebieden in de hersenen en
        in het lichaam ligt opgeslagen in de vorm van{" "}
        <b>onverwerkte ”informatie-capsules” worden aangesproken</b> en zich
        gaan oplossen.
      </TextBlock>
      <TextBlock>
        Door het vastzetten van de brainspot, het punt waar je langer naar
        kijkt, en met een goede en juiste afstemming tussen therapeut en cliënt,
        kan het brein op eigen kracht de sporen van trauma en/of diepe blokkades
        verwerken en helen. De 'Brainspot' wordt gestimuleerd terwijl de cliënt
        zich richt op de lichamelijke, zintuiglijke en geestelijke waarneming.
        <b> Het brein start automatisch een lichaamseigen helingsproces</b> dat
        ervoor zorgt dat bepaalde systemen die in onevenwicht zijn geraakt,
        worden bevrijd en geïntegreerd. Dit proces is voor alle duidelijkheid
        veilig en pijnloos.
      </TextBlock>
      <TextBlock>
        Onder begeleiding van zachte bilaterale ontspanningsmuziek worden
        pijnlijke herinneringen verwerkt. Er wordt als het ware een zachte
        nieuwe laag overheen gelegd. Op deze manier worden de{" "}
        <b>scherpe randen van een herinnering zacht gemaakt</b>. Hierdoor zal je
        merken dat je minder tot helemaal niet meer overspoeld wordt door pijn,
        verdriet, angst of stress wanneer je wordt getriggerd.
      </TextBlock>
      <TextBlock>
        Brainspotting is m.a.w. een tool die begint waar de meeste klassieke
        praattherapieën eindigen. Met Brainspotting is het mogelijk{" "}
        <b>
          ervaringen te verwerken en symptomen aan te pakken die zich buiten het
          bereik van onze bewuste geest en verstandelijke vermogens bevinden
        </b>
        .
      </TextBlock>
      <TextBlock>
        Brainspotting heeft daarmee niet alleen een positief effect op onze
        kwaliteit van leven van alle dag maar is ook bijzonder effectief
        gebleken waar het gaat om de verbetering van prestaties voor mensen die
        onder hoge druk moeten presteren: artiesten, sporters en executives.
      </TextBlock>
      <h2>Brainspotting is</h2>
      <ul>
        <li>Laagdrempelig en voor alle leeftijden geschikt.</li>
        <li>
          Beperkt taalafhankelijk: cliënt en therapeut moeten niet over een
          diepgaande kennis van elkaars taal of cultuur beschikken.
        </li>
        <li>Geen noodzaak om over de problemen te kunnen of moeten praten.</li>
        <li>
          Effectief op een breed spectrum van lichamelijke en psychische
          aandoeningen.
        </li>
        <li>
          Diep bereik in de bewuste en onderbewuste gebieden van de hersenen (
          prae-cognitieve en prae- verbale gebieden).
        </li>
        <TextBlock>
          <i>En verder nog:</i>
        </TextBlock>
        <li>Minder risico op hertraumatisering.</li>
        <li>
          Blijvend en diepgaand resultaat op het gebied van kwaliteit van leven
          en performance.
        </li>
      </ul>
      <h2>Geschikt voor mensen met o.a.</h2>
      <ul>
        <li>Angstaandoeningen</li>
        <li>Stressgerelateerde klachten</li>
        <li>Lichamelijke klachten</li>
        <li>Depressie</li>
        <li>Trauma</li>
        <li>Verslavingen (alcohol, drugs, gokken,...)</li>
        <li>Hechtingsproblematiek</li>
        <li>Posttraumatische stress (PTSS)</li>
        <li>Rouw</li>
        <li>Dwangklachten</li>
        <li>...</li>
      </ul>
      <TextBlock>
        en alle andere blokkades die ons functioneren en presteren negatief
        beïnvloeden. Brainspotting laat zich uitstekend combineren en integreren
        met bestaande therapievormen dan wel andere wijzen van begeleiden van
        mensen, maar kan ook op zichzelf gebruikt worden.
      </TextBlock>
      <h2>Hoeveel sessies</h2>
      <TextBlock>
        Het is afhankelijk van jouw vraagstukken, ervaringen en onbewuste
        blokkades hoeveel sessies je nodig hebt. Ook is het afhankelijk hoe
        scherp de herinnering nog is. Een ervaring die korter geleden is
        gebeurd, is makkelijker op te roepen dan ervaringen uit een ver
        verleden.
      </TextBlock>
    </Box>
  </DepressieWrapper>
)

export default Depressie

const DepressieWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px;
`

const StyledLink = styled(Link)`
  text-decoration: underline;
  color: #00628f;
  display: block;
  margin-bottom: 25px;
  font-size: 1.1em;
`

const VideoWrapper = styled.div`
  display: block;
  margin-bottom: 50px;
`

const Stylediframe = styled.iframe`
  margin: 0 auto;
  display: block;
  max-width: 100%;
  border: none;
`
